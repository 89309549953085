export function setDisabledFields(cusArr, initArr) {
    let selfields = cusArr.map(item => item.keyName);
    let oldArr = [...initArr]
    oldArr.forEach(item => {
        item.disabled = false
    });
    if (selfields) {
        selfields.forEach(f => {
            let index = oldArr.findIndex(c => c.keyName == f);
            if (index != -1) {
                oldArr[index].disabled = true;
            }
        });
    }
    return oldArr
}