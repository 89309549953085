<template>
  <!-- 导航菜单 -->
  <el-menu
    :class="data.customClass"
    :background-color="data.bgc"
    :default-active="data.activeNavIndex"
    :mode="data.mode"
    @select="handleSelectNav"
  >
    <el-menu-item
      :disabled="item.disabled ? item.disabled : false"
      :index="String(item.id)"
      v-for="item in data.navMenu"
      :key="item.id"
    >
      <slot name="content" :item="item">{{ item.name }}</slot>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
      default: function() {
        return {
          navMenu: [
            { id: "1", name: "menu1" },
            { id: "2", name: "menu2" },
            { id: "3", name: "menu3" }
          ],
          activeNavIndex: "1",
          bgc: "",
          mode: "horizontal",
          customClass: ""
        };
      }
    }
  },
  methods: {
    //选择导航菜单时触发
    handleSelectNav(key, keyPath) {
      // console.log(key, keyPath);
      this.$emit("selectedNav", key);
    }
  }
};
</script>

<style lang="scss">
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
</style>