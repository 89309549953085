<template>
  <el-dialog
    custom-class="all-info common-dialog"
    :title="title"
    :visible="visible"
    :width="width"
    @close="closeDialog"
    :append-to-body="true"
  >
    <div>
      <!-- <h2>{{ currentRow.jobName }}</h2> -->
      <h2>{{ currentRow.jobInstance.jobName }}</h2>
      <div class="param">
        <h3>parameters:</h3>
        <div class="param-content" v-if="!currentRow.jobParameters.empty">
          <div
            v-for="(item, name, index) in currentRow.jobParameters.parameters"
            :key="index + 'a'"
          >
            <p v-if="item.value">
              <strong>{{ name }}:</strong>
              {{ item.value }}
            </p>
          </div>
        </div>
      </div>
      <p class="param-p">
        <strong>status:</strong>
        <span :style="{ color: currentRow.status == 'FAILED' ? 'red' : '' }">{{
          currentRow.status
        }}</span>
        <strong>exitCode:</strong>
        <span
          :style="{
            color: currentRow.exitStatus.exitCode == 'FAILED' ? 'red' : '',
          }"
          >{{ currentRow.exitStatus.exitCode }}</span
        >
      </p>
      <p class="param-p">
        <strong>startTime:</strong>
        <span>{{ currentRow.startTime }}</span>
        <strong>endTime:</strong>
        <span>{{ currentRow.endTime }}</span>
      </p>
      <p v-if="currentRow.exitStatus.exitDescription">
        <strong>exitDescription:</strong>
        <span>{{ currentRow.exitStatus.exitDescription }}</span>
      </p>
      <hr />
      <h1>step</h1>
      <div class="step-wrap">
        <div
          v-for="(item, index) in currentRow.stepExecutions"
          :key="index + 'c'"
        >
          <div class="step">
            <h3>步骤：{{ item.stepName }}：</h3>
            <div>
              <p class="step-content">
                <span
                  :style="{ color: item.status == 'FAILED' ? 'red' : '' }"
                  >{{ item.status }}</span
                >
                <span
                  :style="{
                    color: item.exitStatus.exitCode == 'FAILED' ? 'red' : '',
                  }"
                  >{{ item.exitStatus.exitCode }}</span
                >
                <span>{{ item.endTime }}</span>
              </p>
              <p class="step-desc" v-if="item.exitStatus.exitDescription">
                <strong>exitDescription:</strong>
                {{ item.exitStatus.exitDescription }}
              </p>
            </div>
            <!--  -->
            
            <!--  -->
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "taskDetail",
  props: ["visible", "title", "width", "currentRow"],
  methods: {
    closeDialog() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style lang="scss" scoped>
.dp-btw {
  display: flex;
  justify-content: space-between;
}
.all-info {
  .param {
    display: flex;
  }
  .param-p {
    @extend .dp-btw;
  }
  .param-content {
    padding-top: 20px;
  }
  .step-content {
    @extend .dp-btw;
    padding-left: 20px;
  }
  .step-desc {
    padding: 0 20px;
  }
}
</style>