<template>
  <el-table
    ref="tableRef"
    :data="tableData"
    :class="[tableParams.className, 'table-self']"
    :height="tableParams.height ? tableParams.height : undefined"
    :max-height="
      tableParams.maxHeight
        ? tableParams.maxHeight
        : tableParams.isTableHeight
        ? tableHeight
        : undefined
    "
    :border="tableParams.border"
    :stripe="tableParams.stripe"
    :show-summary="showSummary ? true : false"
    :sum-text="sumText ? sumText : '合计'"
    :summary-method="getSummaries"
    v-loading="loading"
    v-infinite-scroll="{
      isInfiniteScroll,
      load: loadMore,
      distance: infiniteScrollDistance
    }"
    :infinite-scroll-immediate="infiniteScrollImmediate"
    :infinite-scroll-disabled="infiniteScrollDisabled"
    :infinite-scroll-distance="infiniteScrollDistance"
    :header-cell-style="headerCellStyle"
    :cell-style="cellStyle"
    :header-cell-class-name="headerCellClassName"
    @selection-change="handleSelectionChange"
    @expand-change="expandChange"
    @cell-mouse-enter="cellMouseEnter"
    @cell-mouse-leave="cellMouseLeave"
    @sort-change="sortChange"
  >
    <!-- 展开列在多选框列之前 -->
    <el-table-column v-if="tableParams.expandBeforeMul" type="expand">
      <template v-slot:default="scope">
        <slot name="expandslot" :scope="scope" :data="previewAllData"></slot>
      </template>
    </el-table-column>
    <el-table-column v-if="tableParams.selection" type="selection" width="55">
    </el-table-column>
    <!-- :render-header="handleRenderHeader" -->
    <el-table-column
      :type="col.expand ? 'expand' : ''"
      :align="col.align ? col.align : 'left'"
      :header-align="col.headerAlign ? col.headerAlign : 'left'"
      :sortable="col.sortable ? col.sortable : false"
      :min-width="col.minWidth ? col.minWidth : 'auto'"
      :width="col.width ? col.width : 'auto'"
      v-for="(col, index) in colData"
      :key="index"
      :label="col.label"
      :prop="col.prop"
      :fixed="col.fixed ? col.fixed : false"
      :show-overflow-tooltip="col.showOverTooltip ? col.showOverTooltip : false"
    >
      <template #header="scope">
        <slot name="cusheader" :scope="scope" :col="col">{{
          scope.column.label ? scope.column.label : ""
        }}</slot>
      </template>
      <template v-slot:default="scope">
        <slot
          name="colslot"
          :scope="scope"
          :prop="col.prop"
          :otherOption="cuscolOtherOption"
          :pindex="pindex"
          >{{ scope.row[col.prop] ? scope.row[col.prop] : "--" }}</slot
        >
      </template>
    </el-table-column>
    <template slot="empty">
      <slot name="cusempty" :pindex="pindex">暂无数据</slot>
    </template>
    <template slot="append">
      <slot name="cusappend" :data="appendData"></slot>
    </template>
  </el-table>
</template>

<script>
import { tableHeight } from "@/utils/utils.js";
export default {
  props: {
    tableHeightData: {
      type: Array,
      default: () => []
    },
    tableParams: {
      type: Object,
      default: () => {
        return {
          stripe: false,
          className: "",
          border: false,
          selection: false, //多选
          maxHeight: "",
          isTableHeight: false,
          height: "",
          expandBeforeMul: false //展开在多选列前
        };
      }
    },
    tableData: {
      type: Array,
      default: () => []
    },
    colData: {
      type: Array,
      default: () => []
    },
    // 列数据的其它自定义配置都可以写在这里(可参考chart-drawer组件)
    cuscolOtherOption: {
      type: Object,
      default: () => {}
    },
    showSummary: {
      type: Boolean,
      default: () => false
    },
    sumText: {
      type: String,
      default: () => "合计"
    },
    getSummaries: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    headerCellStyle: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    cellStyle: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    headerCellClassName: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    cellMouseEnter: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    cellMouseLeave: {
      type: Function,
      default: () => {
        return () => {};
      }
    },
    // 是否无限滚动加载
    isInfiniteScroll: {
      type: Boolean,
      default: () => false
    },
    // 无限滚动禁用
    infiniteScrollDisabled: {
      type: Boolean,
      default: () => false
    },
    infiniteScrollImmediate: {
      type: Boolean,
      default: () => false
    },
    infiniteScrollDistance: {
      type: Number,
      default: () => 0
    },
    // 插至表格最后一行之后的内容（加载中-没有更多）
    appendData: {
      type: Object,
      default: () => {}
    },
    // 当前子表所在的行index
    pindex: {
      type: Number,
      default: () => 0
    },
    // 广告资产-批量操作-查看原值-全部
    previewAllData: {
      type: Array,
      default: () => []
    }
  },
  directives: {
    "infinite-scroll": {
      bind(el, binding) {
        // console.log("directives-bind(el, binding)", el, binding);
        if (binding.value.isInfiniteScroll) {
          // console.log(
          //   "directives-isInfiniteScroll",
          //   binding.value.isInfiniteScroll
          // );
          let wrap = el.querySelector(".el-table__body-wrapper");
          wrap.addEventListener("scroll", function() {
            let sign = binding.value.distance;
            let scrollDistance =
              this.scrollHeight - this.scrollTop - this.clientHeight;
            if (scrollDistance <= sign) {
              binding.value.load();
            }
          });
        }
      }
    }
  },
  data() {
    return {
      tableHeight: 200
    };
  },
  methods: {
    // 改变table高度
    changeTableHeight() {
      this.$nextTick(() => {
        if (this.tableHeightData.length) {
          this.tableHeight = tableHeight(this.tableHeightData);
          console.log(this.tableHeight);
        }
      });
    },
    handleChangeRuleStatus(val, row, pRowIndex) {
      this.$emit("change-rule-status", val, row, pRowIndex);
    },
    handleSelectionChange(val) {
      this.$emit("select-change", val);
    },
    handleRenderHeader(h, { column, $index }) {
      if (column.sortable) {
        return (
          <el-tooltip
            class="item"
            effect="dark"
            content={
              column.order == "ascending"
                ? "点击降序"
                : column.order == "descending"
                ? "取消排序"
                : "点击升序"
            }
            placement="top"
          >
            <span>{column.label}</span>
          </el-tooltip>
        );
      } else {
        return <span>{column.label}</span>;
      }
    },
    loadMore() {
      this.$emit("load-more");
    },
    expandChange(obj, arr) {
      // console.log("expandChange(obj,arr)", obj, arr);
      this.$emit("expand-change", obj, arr);
    },
    // 表格排序
    sortChange(v){
      this.$emit('sort-change',v)
    }
  },
  created(){
    this.$bus.$on("trigger-height-change",()=>{
      this.changeTableHeight()
    })
    this.$bus.$on("trigger-height-change-page",(num)=>{
      this.$nextTick(() => {
        if (this.tableHeightData.length) {
          this.tableHeight = tableHeight(this.tableHeightData)-num;
        }
      });
    })
  },
  mounted() {
    this.changeTableHeight();
  }
};
</script>

<style lang="scss">
@import "@/styles/commonly";
.table-self {
  .el-table__row:nth-child(2n) {
    background: #f8f8fa;
  }
}
</style>